import React from 'react';
import i18n from 'utils/i18n';
import getConfig from 'next/config';
import { PageProvider } from 'providers/page';
import SplashScreen from 'components/organisms/splash-screen';

import css from './styles.module.scss';
import { useUser } from '@auth0/nextjs-auth0/client';
import { useTenant } from 'providers/tenant';
import { useUserSettings } from 'providers/user-settings';
import { useProduct } from 'providers/product';

const { publicRuntimeConfig } = getConfig();
let SPAEl = null;

function Index(props) {
  const [showSplashScreen, setShowSplashScreen] = React.useState(true);
  const [SPAReady, setSPAReady] = React.useState(null);

  const userData = useUser();
  const tenantCtx = useTenant();
  const productCtx = useProduct();
  const settings = useUserSettings();

  async function loadSPADependencies() {
    const SPA = (await import('components/organisms/spa')).default;
    SPAEl = SPA;
  }

  React.useEffect(
    (_) => {
      if (userData.user) {
        const locale = userData.user[`${publicRuntimeConfig.AUTH0_NAMESPACE}/user_info`]?.locale;
        // add location default
        tenantCtx.setLocale(locale || "pt-br");
        settings.fetchData();
        productCtx.fetchData();

        loadSPADependencies().then(() => {
          setSPAReady(true);
          setTimeout((_) => {
            setShowSplashScreen(false);
          }, 250);
        });

      } else {
        setSPAReady(false);
        setShowSplashScreen(true);
      }
    },
    [userData.user]
  );


  React.useEffect((_) => {
    if (window.opener && window.name === 'authenticate') {
      // in case it is inside the auth0 popup...
      window.opener.postMessage({
        code: 'ok',
        source: 'auth0-callback-popup'
      });
      window.opener.focus();
      window.close();
      // window.opener.location.reload();
    }
  }, []);

  async function checkUser() {
    userData.checkSession();
  }

  React.useEffect((_) => {
    function messageListener(event) {
      if (event.origin !== location.origin) {
        console.error('Unacceptable');
        return;
      }

      if (event.data.code && event.data.source === 'auth0-callback-popup') {
        checkUser();
      }
    }

    window.addEventListener('message', messageListener);

    return (_) => {
      window.removeEventListener('message', messageListener);
    };
  }, []);

  return (
    <div className={css['page__homeContainer']} data-page-container="1">
      {SPAReady && <SPAEl />}
      {showSplashScreen && (
        <SplashScreen
          className={css['splash-screen-container']}
          data-fading={SPAReady ? 1 : null}
        />
      )}
    </div>
  );
}

export default function IndexPage(props) {
  const { children, ...other } = props;
  return (
    <PageProvider>
      <Index {...other} />
      {children}
    </PageProvider>
  );
}

export async function getStaticProps({ locale }) {
  return {
    props: {
      messages: await i18n.getMessages(locale)
    }
  };
}
