import React from 'react';
import pkg from 'package.json';
import { Button, Icon } from 'semantic-ui-react';

const { version } = pkg;

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism SplashScreen
 *
 * The splash screen, used before loading and opening the system
 */
function SplashScreen(props) {
  const { className = '', children, ...other } = props;

  function openSign () {
    const loginURL = '/api/auth/login';
    const w = 420;
    const h = 760;
    const l = screen.width / 2 - w / 2;
    const t = screen.height / 2 - h / 2;
    let params = `scrollbars=no,resizable=no,status=no,location=no,toolbar=no,menubar=no,width=${w},height=${h},left=${l},top=${t}`;
    window.open(loginURL, 'authenticate', params);
  }

  return (
    <div className={`${css['organism__splash-screen-container']} ${className}`} {...other}>
      <div className={css['glass-effects-container']} />

      <div className={css['content-container']} data-content-wrapper="true">
        <div className={css['header']}>
          <img src="/images/logo/splash-logo.png"
            alt="Advanced Management Online System" />

          <div>
            T-AMOS
            <br />
            <h3>Toyota Advanced Management Online System</h3>
          </div>
        </div>
        <p>
          The system to manage internal requirements and processes.
          <br />
          For support, please contact the system administrators.
          <br />
          This is a private area and you should enter your credentials
          <br />
          to have access.
          <br />
          Also, by authenticating,
            you agree on allowing the use of cookies in this page.
        </p>

        <div className={`${css['enter-button-container']} ${css['loading-container']}`}>
          <a>
            <Button
              size="large"
              onClick={(_) => {
                openSign();
                // if (location) location.href = '/api/auth/login';
              }}
            >
              <Icon name="lock" />
              Enter
            </Button>
          </a>
        </div>
      </div>
      <div className={css['version-container']}>
        v{version}
        <br />
        Development:{' '}
        <a href="https://on2.dev/" target="blank">
          On2 Technologies
        </a>
      </div>
      {children}
    </div>
  );
}

export default SplashScreen;
